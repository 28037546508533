import path from 'path'
import { CrudSubRoutesEnum } from './CrudEnum';
import { defaultPaginationLimit, FilterSortPageType, FilterType, InteractionManagerTyped, SortType } from 'components/Table';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { setColumnsOrderAction, setColumnsWidthAction, setFiltersAction, setSortAction } from 'features/UserTableViewConfig/tableConfig/action';
import { ColumnsOrderType, ColumnsWidthType } from 'features/UserTableViewConfig/tableConfig/reducer';
import { emptyObject } from 'utils/commonHelper';

type ReturnType<T> = {
	interactionManager: InteractionManagerTyped<T>
	columnsOrder?: ColumnsOrderType
	columnsWidth?: ColumnsWidthType
	filterSortPage: FilterSortPageType
}

const getSort = (columnId: string | undefined, defaultSort: SortType, filterSortPage: FilterSortPageType) => {
	if (!columnId) {
		return defaultSort;
	}

	// if some other column is sorted, just set new column and ascending
	if (columnId !== filterSortPage.sort?.columnId) {
		return { columnId, isAsc: true };
	}

	// if clicked on the same column sort icon, change sorting to next one

	if (filterSortPage.sort.isAsc) {
		// ascending -> descending
		return { columnId, isAsc: false };
	} else if (defaultSort.columnId !== columnId) {
		// descending -> no sort (only if it is not default column)
		return defaultSort;
	} else {
		// no sort -> ascending
		return { columnId, isAsc: true };
	}
}

export const useTableInteractionManager = <T>(tableId: string, defaultSortColumnId?: string, defaultSortIsAsc?: boolean): ReturnType<T> => {
	const history = useHistory();
	const tableConfig = useSelector((state: RootState) => state.persistedTablesConfig.value[tableId]);

	const [selectedRow, setSelectedRow] = useState<T>({} as T);
	const [offset, setOffset] = useState(0);

	const defaultSort = useMemo(
		(): SortType => ({
			columnId: defaultSortColumnId,
			isAsc: defaultSortIsAsc
		}),
		[defaultSortColumnId, defaultSortIsAsc]
	)

	const filterSortPage = useMemo(
		() => ({
			offset,
			limit: defaultPaginationLimit,
			sort: tableConfig?.sort || defaultSort,
			filters: tableConfig?.filters
		}),
		[offset, tableConfig?.sort, tableConfig?.filters, defaultSort]
	)

	const click = useCallback(
		(rowData: any) => {
			setSelectedRow((state) => state !== rowData ? rowData : emptyObject);
		},
		[]
	);

	const doubleClick = useCallback(
		(rowData: any) => {
			// INFO: Jedina tabela koja je specificna i nema svoj ID (konfiguracija joj je cudna jako) i za nju nismo podrzali double/middle click:
			// https://domain2020.mavles-staging.com/time_travel/approval/timesheets/read/2/20/2/1727733600000/1730329200000
			history.push(path.join(history.location.pathname, CrudSubRoutesEnum.Read, String(rowData.id)));
		},
		[history]
	)

	const middleClick = useCallback(
		(rowData: any) => {
			window.open(path.join(history.location.pathname, CrudSubRoutesEnum.Read, String(rowData.id)), '_blank');
		},
		[history]
	)

	const reorderColumn = useCallback(
		async (columnsOrder: string[] | undefined) => {
			await setColumnsOrderAction(tableId, columnsOrder);
		},
		[tableId]
	)

	const resizeColumn = useCallback(
		async (columnId: string, size: number | undefined) => {
			const newColumnsWidth = { ...tableConfig?.columnsWidth }
			if (newColumnsWidth[columnId] === size) {
				return;
			}

			if (size) {
				newColumnsWidth[columnId] = size;
			} else {
				// size is auto, so just remove it
				delete newColumnsWidth[columnId];
			}
			await setColumnsWidthAction(tableId, newColumnsWidth);
		},
		[tableId, tableConfig]
	)

	const resetAllColumnWidths = useCallback(
		async () => {
			await setColumnsWidthAction(tableId, {});
		},
		[tableId]
	)

	// Three click sorting on the column: ascending, descending, and reset to default sort
	const sort = useCallback(
		(columnId: string | undefined) => {
			const newSort = getSort(columnId, defaultSort, filterSortPage);
			setSortAction(tableId, newSort);
		},
		[defaultSort, filterSortPage, tableId]
	)

	const filter = useCallback(
		(filters: FilterType[]) => {
			setFiltersAction(tableId, filters);
			setOffset(0);
		},
		[tableId]
	)

	return useMemo(
		() => ({
			interactionManager: {
				click,
				doubleClick,
				middleClick,
				reorderColumn,
				resizeColumn,
				resetAllColumnWidths,
				sort,
				filter,
				changeOffset: setOffset,
				selectedRow
			},
			columnsOrder: tableConfig?.columnsOrder,
			columnsWidth: tableConfig?.columnsWidth,
			filterSortPage
		}),
		[click, doubleClick, middleClick, reorderColumn, resizeColumn, resetAllColumnWidths, sort, filter, setOffset, selectedRow, tableConfig, filterSortPage]
	)
}
