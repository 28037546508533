import { useCallback, useMemo } from 'react';
import { Dialog } from 'components/Dialog';
import { GenericFilterModel } from 'services/tenantManagementService';
import { ActiveFilter } from './ActiveFilter/ActiveFilter';
import FilterElement from './FilterTypes/FilterElement';
import { BaseColumnModel } from '../BaseColumnModel';
import { Translation } from 'components/Translations/Translation';

export class FilterModel extends GenericFilterModel {}

type Props = {
	column?: BaseColumnModel
	columns: BaseColumnModel[]
	filters: FilterModel[]
	onCancel: () => void;
	onSave: (filters: FilterModel[]) => void;
}

const Filter = ({ column, columns, filters, onCancel, onSave }: Props) => {
	const clearAllCallback = useCallback(
		() => onSave([]),
		[onSave]
	)

	// const deleteCallback = useCallback(
	// 	(filterForDelete: FilterModel) => {
	// 		// check is it and filter
	// 		const newFilters = [...filters];
	// 		const andIndex = newFilters.indexOf(filterForDelete);
	// 		if (andIndex !== -1) {
	// 			newFilters.splice(andIndex, 1)
	// 			onSave(newFilters);
	// 			return;
	// 		}
	// 		// otherwise find it in or filters
	// 		newFilters.forEach((filter, index) => {
	// 			if (filter.orFilters) {
	// 				const orIndex = filter.orFilters.indexOf(filterForDelete);
	// 				if (orIndex !== -1) {
	// 					const newOrFilters = [...filter.orFilters];
	// 					newOrFilters.splice(orIndex, 1);

	// 					if (newOrFilters.length > 0) {
	// 						filter.orFilters = newOrFilters;
	// 						return;
	// 					}

	// 					// if there are not orFilters anymore, remove parent filter
	// 					newFilters.splice(index, 1);
	// 					return;
	// 				}
	// 			}
	// 		})

	// 		onSave(newFilters)
	// 	},
	// 	[onSave, filters]
	// )

	const showActiveFilter = useMemo(
		() => {
			for (let i = 0; i < columns.length; i++) {
				const field = columns[i].dbFilterFieldPath;

				if (filters && filters.length > 0 && filters.find(item => item.property === field || item.orFilters?.find(orFilterItem => orFilterItem.property === field))) {
					return true;
				}
			}

			return false;
		},
		[filters, columns]
	)

	return (
		<>
			{showActiveFilter && (
				<ActiveFilter
					filters={filters}
					columns={columns}
					onClearAll={clearAllCallback}
				/>
			)}
			{column &&
				<Dialog
					title='i18n.button.filter'
					onClose={onCancel}
					open={!!column}
				>
					<h5>
						<Translation i18n={column.title} />
					</h5>
					<FilterElement
						type={column.fieldType}
						column={column}
						filters={filters}
						onSave={onSave}
						onCancel={onCancel}
					/>
				</Dialog>
			}
		</>
	)
}

export default Filter;
