import Button from 'components/Button';
import { Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterType, OptionsColumnModel } from 'components/Table';

type Props = {
	column: OptionsColumnModel<any>
	filters: FilterType[]
	onSave: (filters: FilterType[]) => void
	onCancel: () => void
}

type State = {
	selectedValues: any[]
}

export const OptionsFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<State>({ selectedValues: [] })

	useEffect(
		() => {
			// only one AND filter can be added for options filter
			const currentFilter = findFilter(filters, column.id);
			if (!currentFilter) {
				setValues({ selectedValues: [] });
			} else if (currentFilter?.columnId === column.id) {
				setValues({ selectedValues: [currentFilter.value] })
			} else if (currentFilter.orFilters && currentFilter.orFilters.length > 0) {
				const newValues = currentFilter.orFilters
					.filter(filter => filter.columnId === column.id)
					.map(filter => filter.value)
				setValues({ selectedValues: newValues });
			}
		},
		[filters, column.id]
	)

	const submitCallback = useCallback(
		async () => {
			// only one "Parent" filter can be added for options filter
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.id);
			let newFilter: FilterType | undefined;
			if (values.selectedValues.length > 1) {
				newFilter = {
					orFilters: values.selectedValues.map((value: any) =>
					({
						columnId: column.id,
						operation: GenericFilterOperationEnum.EQUALS,
						value
					})
					)
				}
			} else if (values.selectedValues.length === 1) {
				newFilter = {
					columnId: column.id,
					operation: GenericFilterOperationEnum.EQUALS,
					value: values.selectedValues[0]
				}
			}
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}
			if (newFilter) {
				newFilters.push(newFilter);
			}
			onSave && await onSave(newFilters);
		},
		[column.id, filters, values, onSave]
	)

	const clearCallback = useCallback(
		() => {
			setValues({ selectedValues: [] });
		},
		[]
	)

	const { items, getItemId, getItemText } = column;

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='i18n.button.apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<MultiSelectField
							id='selectedValues'
							label='i18n.label.selectOptions'
							items={items}
							getItemId={getItemId}
							getItemText={getItemText}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='i18n.button.clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
