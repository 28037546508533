import { convertTicksToTime, convertTimeToTicks } from "utils/dateTimeUtils";
import { SelectionType } from "../Input/Input";
import { MeridiemTimeEnum } from "utils/MeridiemTimeEnum";

export enum SelectionEnum {
	Hours = 'hours',
	Minutes = 'minutes',
	AmPm = 'amPm'
}

export const selectionOrder12h = [SelectionEnum.Hours, SelectionEnum.Minutes, SelectionEnum.AmPm];
export const selectionOrder24h = [SelectionEnum.Hours, SelectionEnum.Minutes];

export type TimeValues = {
	hours?: number
	minutes?: number
	amPm?: MeridiemTimeEnum | undefined
}

export type TimeValuesString = {
	hours: string
	minutes: string
	amPm: string
}

export const toggleAmPm = (values: TimeValues) => {
	let amPm = values.amPm;
	if (amPm === undefined || amPm === MeridiemTimeEnum.PM) {
		amPm = MeridiemTimeEnum.AM;
	} else {
		amPm = MeridiemTimeEnum.PM;
	}
	return {
		...values,
		amPm
	}
}

/** Increase value in selection (for example: increase hours if selection is hours) */
export const increaseSelection = (values: TimeValues, selection: SelectionEnum | undefined, isDuration: boolean, is24h: boolean): TimeValues => {
	switch (selection) {
		case SelectionEnum.Hours: {
			let hours = values.hours;
			if (isDuration) {
				if (hours === undefined || hours === 99) {
					hours = 0;
				} else {
					hours++;
				}
			} else if (is24h) {
				if (hours === undefined || hours === 23) {
					hours = 0;
				} else {
					hours++;
				}
			} else {
				if (hours === undefined || hours === 11) {
					hours = 0;
				} else if (hours === 23) {
					hours = 12;
				} else {
					hours++;
				}
			}

			return {
				...values,
				hours
			}
		}
		case SelectionEnum.Minutes:
			let minutes = values.minutes;
			if (minutes === undefined || minutes === 59) {
				minutes = 0;
			} else {
				minutes++;
			}

			return {
				...values,
				minutes
			}
		case SelectionEnum.AmPm:
			return toggleAmPm(values);
		default:
			return values;
	}
}

/** Decrease value in selection (for example: decrease hours if selection is hours) */
export const decreaseSelection = (values: TimeValues, selection: SelectionEnum | undefined, isDuration: boolean, is24h: boolean): TimeValues => {
	switch (selection) {
		case SelectionEnum.Hours: {
			let hours = values.hours;
			if (isDuration) {
				if (hours === undefined || hours === 0) {
					hours = 99;
				} else {
					hours--;
				}
			} else if (is24h) {
				if (hours === undefined || hours === 0) {
					hours = 23;
				} else {
					hours--;
				}
			} else {
				if (hours === undefined || hours === 0) {
					hours = 11;
				} else if (hours === 12) {
					hours = 23;
				} else {
					hours--;
				}
			}

			return {
				...values,
				hours
			}
		}
		case SelectionEnum.Minutes:
			let minutes = values.minutes;
			if (minutes === undefined || minutes === 0) {
				minutes = 59;
			} else {
				minutes--;
			}

			return {
				...values,
				minutes
			}
		case SelectionEnum.AmPm:
			return toggleAmPm(values);
		default:
			return values;
	}
}

export const convertToTicks = (values: TimeValues) => {
	if (values.hours === undefined || values.minutes === undefined) {
		return undefined;
	}

	const hours = values.amPm === MeridiemTimeEnum.PM ? values.hours + 12 : values.hours;

	return convertTimeToTicks(hours, values.minutes);
}

export const convertToTime = (ticks: number | undefined, is24h: boolean): TimeValues => {
	if (ticks === undefined) {
		return {
			hours: undefined,
			minutes: undefined,
			amPm: undefined
		}
	}

	let { hours, minutes } = convertTicksToTime(ticks);

	if (is24h) {
		return { hours, minutes };
	} else {
		const amPm = Math.floor(hours / 12) === 0 ? MeridiemTimeEnum.AM : MeridiemTimeEnum.PM;
		hours = hours % 12;
		return { hours, minutes, amPm };
	}
}

export const changeSelection = (selection: SelectionEnum | undefined, selectionOrder: SelectionEnum[], toTheRight: boolean) => {
	if (selection === undefined) {
		return SelectionEnum.Hours;
	}

	let index = selectionOrder.indexOf(selection);
	index = toTheRight ? index + 1 : index - 1;

	if (index < 0) {
		index = selectionOrder.length - 1;
	}

	if (index >= selectionOrder.length) {
		index = 0;
	}

	return selectionOrder[index];
}

export const getInputSelection = (selectionEnum: SelectionEnum | undefined): SelectionType => {
	switch (selectionEnum) {
		case SelectionEnum.Minutes:
			return { start: 3, end: 5 }
		case SelectionEnum.AmPm:
			return { start: 6, end: 8 }
		case SelectionEnum.Hours:
			return { start: 0, end: 2 }
		default:
			return { start: 0, end: 0 };
	}
}

export const getSelectionEnum = (inputSelectionStart: number | null) => {
	switch (inputSelectionStart) {
		case 3:
		case 4:
		case 5:
			return SelectionEnum.Minutes;
		case 6:
		case 7:
			return SelectionEnum.AmPm;
		default:
			return SelectionEnum.Hours;
	}
}
