import Button from 'components/Button';
import { DateField, Form, RadioField, SelectField, RadioItemType } from 'components/Form';
import { SmartContainer, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { AndOrEnum, findAllFilters } from '../../filtersHelper';
import { DateFilterModel } from './DateFilterModel';
import { FilterModel } from '../../Filter';
import { BaseColumnModel } from 'components/Table';

type Props = {
	column: BaseColumnModel
	filters: FilterModel[]
	onSave: (filters: FilterModel[]) => void
	onCancel: () => void
}

const filterTypeOptions = [
	{ value: GenericFilterOperationEnum.EQUALS, text: 'Equal' },
	{ value: GenericFilterOperationEnum.NOT_EQUALS, text: 'Not equal' },
	{ value: GenericFilterOperationEnum.GREATER_EQUAL_THAN, text: 'Greater or equal than' },
	{ value: GenericFilterOperationEnum.GREATER_THAN, text: 'Greater than' },
	{ value: GenericFilterOperationEnum.LESS_EQUAL_THAN, text: 'Less or equal than' },
	{ value: GenericFilterOperationEnum.LESS_THAN, text: 'Less than' },
]

const additionalFilterOptions: RadioItemType[] = Object.values(AndOrEnum).map(option => {
	return {
		id: option,
		text: option,
	}
});

export const DateFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<DateFilterModel>(new DateFilterModel());

	useEffect(
		() => {
			const newValues = new DateFilterModel();

			const currentFilters = findAllFilters(filters, column.dbFilterFieldPath);
			// if there are two filters that means AND filter is used
			if (currentFilters && currentFilters.length === 2) {
				newValues.additionalFilter = AndOrEnum.And;
				newValues.filterType1 = currentFilters[0].operation;
				newValues.filterValue1 = currentFilters[0].value;
				newValues.filterType2 = currentFilters[1].operation;
				newValues.filterValue2 = currentFilters[1].value;
			// if there is one filter, but without OR filters
			} else if (currentFilters && currentFilters.length === 1 && (!currentFilters[0].orFilters || currentFilters[0].orFilters.length === 0)) {
				newValues.additionalFilter = AndOrEnum.And;
				newValues.filterType1 = currentFilters[0].operation;
				newValues.filterValue1 = currentFilters[0].value;
			// if there is one filter, with two OR filters
			} else if (currentFilters && currentFilters.length === 1 && currentFilters[0].orFilters && currentFilters[0].orFilters.length === 2) {
				newValues.additionalFilter = AndOrEnum.Or;
				newValues.filterType1 = currentFilters[0].orFilters[0].operation
				newValues.filterValue1 = currentFilters[0].orFilters[0].value
				newValues.filterType2 = currentFilters[0].orFilters[1].operation
				newValues.filterValue2 = currentFilters[0].orFilters[1].value
			// if there is one filter, with one OR filters
			} else if (currentFilters && currentFilters.length === 1 && currentFilters[0].orFilters && currentFilters[0].orFilters.length === 1) {
				newValues.additionalFilter = AndOrEnum.Or;
				newValues.filterType1 = currentFilters[0].orFilters[0].operation
				newValues.filterValue1 = currentFilters[0].orFilters[0].value
			}

			setValues(newValues);
		},
		[filters, column.dbFilterFieldPath]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];
			// remove current filters with specific dbFilterFieldPath
			const currentFilters = findAllFilters(newFilters, column.dbFilterFieldPath);
			if (currentFilters.length > 0) {
				currentFilters.forEach(filter => {
					const index = newFilters.indexOf(filter);
					newFilters.splice(index, 1);
				})
			}

			const newFilter = new FilterModel();
			// If OR filter is selected, add filters to orFilters array
			if (values.additionalFilter === AndOrEnum.Or) {
				newFilter.orFilters = []
				if (values.filterType1) {
					newFilter.orFilters.push(
						new FilterModel({
							property: column.dbFilterFieldPath,
							operation: values.filterType1,
							value: values.filterValue1
						})
					);
				}

				if (values.filterType2) {
					newFilter.orFilters.push(
						new FilterModel({
							property: column.dbFilterFieldPath,
							operation: values.filterType2,
							value: values.filterValue2
						})
					)
				}
				if (newFilter.orFilters.length > 0) {
					newFilters.push(newFilter);
				}
			}
			// If AND filter is selected add "Parent" filter
			if (values.additionalFilter === AndOrEnum.And) {
				if (values.filterType1) {
					newFilters.push(
						new FilterModel({
							property: column.dbFilterFieldPath,
							operation: values.filterType1,
							value: values.filterValue1
						})
					)
				}

				// If second filter is selected add new "Parent" filter
				if (values.filterType2) {
					newFilters.push(
						new FilterModel({
							property: column.dbFilterFieldPath,
							operation: values.filterType2,
							value: values.filterValue2
						})
					)
				}
			}

			onSave && await onSave(newFilters);
		},
		[filters, column.dbFilterFieldPath, onSave, values]
	)

	const clearCallback = useCallback(
		() => {
			setValues(new DateFilterModel()) ;
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='i18n.button.apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SmartInline>
							<SelectField
								id='filterType1'
								items={filterTypeOptions}
								getItemId={(item) => item.value}
								getItemText={(item) => item.text}
								inline
							/>
							<DateField
								id='filterValue1'
								inline
							/>
						</SmartInline>
						<RadioField
							id='additionalFilter'
							items={additionalFilterOptions}
						/>
						<SmartInline>
							<SelectField
								id='filterType2'
								items={filterTypeOptions}
								getItemId={(item) => item.value}
								getItemText={(item) => item.text}
								inline
							/>
							<DateField
								id='filterValue2'
								inline
							/>
						</SmartInline>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='i18n.button.clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
