import { getLocale, setLocale } from 'components/Translations/translationUtils';
import { getThemeLocalStorage, setThemeLocalStorage } from 'features/Theme';
import { UserModel } from 'services/tenantManagementService';

export const USER_INFO = 'user_info';
export const IS_USER_PM = 'is_user_pm';
export const IS_USER_ORGANIZATION_UNIT_MANAGER = 'is_user_manager_at_any_organizational_unit';
export const IS_USER_RESOURCE_MANAGER = 'is_user_resource_manager';

export const getUserInfo = () => {
	const userString = localStorage.getItem(USER_INFO);
	if (userString === null) {
		return new UserModel();
	}

	const userInfo: UserModel = JSON.parse(userString);

	return userInfo;
}

export const setUserInfo = (user: UserModel) => {
	localStorage.setItem(USER_INFO, JSON.stringify(user))
}

export const getIsUserProjectManager = () : boolean  => {
	return localStorage.getItem(IS_USER_PM) === 'true';
}

export const setIsUserProjectManager = (isUserPM: boolean) => {
	localStorage.setItem(IS_USER_PM, isUserPM.toString());
}

export const getIsUserManagerAtAnyOrganizationalUnit = () : boolean  => {
	return localStorage.getItem(IS_USER_ORGANIZATION_UNIT_MANAGER) === 'true';
}

export const setIsUserManagerAtAnyOrganizationalUnit = (isOrganizationalUnitManager: boolean) => {
	localStorage.setItem(IS_USER_ORGANIZATION_UNIT_MANAGER, isOrganizationalUnitManager.toString());
}

export const getIsUserResourceManager = () : boolean  => {
	return localStorage.getItem(IS_USER_RESOURCE_MANAGER) === 'true';
}

export const setIsUserResourceManager = (isOrganizationalUnitManager: boolean) => {
	localStorage.setItem(IS_USER_RESOURCE_MANAGER, isOrganizationalUnitManager.toString());
}

export const clearStorage = () => {
	const theme = getThemeLocalStorage();
	const locale = getLocale();
	localStorage.clear();
	theme && setThemeLocalStorage(theme);
	locale && setLocale(locale);
}
