import { useCallback, useRef } from 'react';
import { ControlsCommonProps } from '../../fields';
import { InsertAttachmentRequest } from 'services/tenantManagementService';
import Button from 'components/Button';

type Props = ControlsCommonProps<InsertAttachmentRequest[]> & {
	multiple?: boolean
	accept?: string
}

export const AddAttachmentButton = ({
	value, // those are new attachments
	onChange,
	disabled,
	onBlur,
	multiple,
	accept,
}: Props) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const onChangeCallback = useCallback(
		(e) => {
			let files = e.target.files;
			let filesLength = files.length;

			if (filesLength === 0) {
				onChange && onChange([]);
			}

			const allFiles: InsertAttachmentRequest[] = [];
			for (let i = 0; i < files.length; i++) {
				let file = files[i];

				// Make new FileReader
				let reader = new FileReader();
				// Convert the file to base64 text
				reader.readAsDataURL(file);
				// on reader load something...
				reader.onload = () => {
					// Make a fileInfo Object
					let fileInfo: InsertAttachmentRequest = new InsertAttachmentRequest({
						name: file.name,
						base64EncodedFile: reader.result as string,
					});

					// Push it to the state
					allFiles.push(fileInfo);

					// If all files have been proceed
					if (allFiles.length === filesLength) {
						// Apply Callback function
						onChange && onChange([...(value || []), ...allFiles]);
					}
				}
			}
		},
		[value, onChange]
	)

	return (
		<>
			<Button
				text='i18n.button.addAttachment'
				color='neutral'
				onClick={() => { inputRef.current && inputRef.current.click() }}
			/>
			<input
				hidden
				ref={inputRef}
				type='file'
				onChange={onChangeCallback}
				onBlur={onBlur}
				disabled={disabled}
				accept={accept}
				multiple={multiple}
			/>
		</>
	)
}
