import { useCallback, useEffect, useMemo, useState } from "react";
import { GenericFilterModelCollection } from "services/tenantManagementService"
import { Subject } from "rxjs";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { emptyArray, noop } from "utils/commonHelper";
import { CustomTable } from "../CustomTable/CustomTable";
import { GenericColumnModel, FilterSortPageType, InteractionManager } from "../CustomTable/models";
import { prepareFiltersModel } from "./prepareFiltersModel";
import { BackendColumnFieldsMap } from "features/TableColumns/prepareExportColumns";
import ErrorBoundary from "features/ErrorBoundary";
import { SkeletonTable } from "../CustomTable/SkeletonTable/SkeletonTable";

type CellEditedReturnType = void | {
	rowData?: any
	/** In specific cases where container does some specific mapping of data and
	 * takes care about rowsData, it needs to send whole rowsData instead of rowData */
	rowsData?: any[]
}

type Props = {
	columns: GenericColumnModel[]
	filterSortPage: FilterSortPageType
	specificBackendColumnFieldsMap?: BackendColumnFieldsMap
	fetchFunction: (genericFilter: GenericFilterModelCollection) => Promise<any>
	subscriptionTopic?: Subject<unknown>
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<CellEditedReturnType>
	hidePagination?: boolean
	disabledReorder?: boolean
	mapResponse?: (items: any) => any
	interactionManager: InteractionManager
}

export const RemoteTable = ({
	columns,
	filterSortPage,
	specificBackendColumnFieldsMap,
	fetchFunction,
	subscriptionTopic,
	cellEdited,
	hidePagination,
	disabledReorder = false,
	mapResponse,
	interactionManager
}: Props) => {
	const [count, setCount] = useState(0);
	const [rowsData, setRowsData] = useState<any[]>(emptyArray);
	const [fetchState, setFetchState] = useState({
		// if it was already fetched (we want to be aware of first fetch because of Skeleton)
		fetched: false,
		// if refetching after the first fetch
		refetching: false
	})

	const fetchDataCallback = useCallback(
		async () => {
			setFetchState((state) => ({
				...state,
				refetching: state.fetched
			}));

			const newFiltersModel = prepareFiltersModel(filterSortPage, specificBackendColumnFieldsMap);

			const bindedAction = fetchFunction.bind(null, newFiltersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				let items = response.items || emptyArray;

				if (mapResponse) {
					items = mapResponse(items);
				}
				setRowsData(items);
				setCount(response.count);
			}

			setFetchState({
				fetched: true,
				refetching: false
			});
		},
		[filterSortPage, specificBackendColumnFieldsMap, fetchFunction, mapResponse]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = subscriptionTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, subscriptionTopic]
	)

	const cellEditedCallback = useCallback(
		async (rowData: any, columnId: string, value: any) => {
			const returnValue = await cellEdited?.(rowData, columnId, value);
			if (!returnValue) {
				return;
			}

			if (returnValue.rowsData) {
				setRowsData(returnValue.rowsData);
				return;
			} else {
				setRowsData((state) => state.map((item) => item === rowData ? returnValue.rowData : item));
			}
		},
		[cellEdited]
	)

	const pagination = useMemo(
		() => {
			if (hidePagination) {
				return undefined;
			}
			return {
				offset: filterSortPage.offset,
				limit: filterSortPage.limit,
				count,
				onChange: interactionManager.changeOffset || noop
			}
		},
		[hidePagination, filterSortPage, count, interactionManager]
	)

	if (!fetchState.fetched) {
		return <SkeletonTable />
	}

	return (
		<ErrorBoundary location='RemoteTable'>
			<CustomTable
				refetching={fetchState.refetching}
				columns={columns}
				rowsData={rowsData}
				filterSortPage={filterSortPage}
				pagination={pagination}
				cellEdited={cellEditedCallback}
				disabledReorder={disabledReorder}
				interactionManager={interactionManager}
			/>
		</ErrorBoundary>
	)
}
