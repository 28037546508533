import { useCallback, useEffect, useMemo, useState } from "react"
import { GenericColumnModel, BooleanColumnModel, DateColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, StringColumnModel, LinkColumnModel, CurrencyColumnModel, FormatterCell, TimeColumnModel, DurationColumnModel } from "../../models"
import { convertMinutesToTicks, convertTicksToMinutes } from "utils/dateTimeUtils"
import { StringEditableCell } from "./StringEditableCell"
import { OptionEditableCell } from "./OptionEditableCell"
import { DateEditableCell } from "./DateEditableCell"
import { NumberEditableCell } from "./NumberEditableCell"
import { BooleanEditableCell } from "./BooleanEditableCell"
import { OptionsEditableCell } from "./OptionsEditableCell"
import { LinkEditableCell } from "./LinkEditableCell"
import { TimeEditableCell } from "./TimeEditableCell"

type Props = {
	column: GenericColumnModel
	value: any
	onChangeComplete(columnId: string, value: any): void
	loading: boolean
	formatterCell: FormatterCell
}

export const EditableCell = ({ column, value, onChangeComplete, loading, formatterCell }: Props) => {
	const [currentValue, setCurrentValue] = useState(value);

	useEffect(
		() => {
			setCurrentValue(column instanceof DurationColumnModel ? convertTicksToMinutes(value) : value);
		},
		[value, column]
	)

	const onBlurCallback = useCallback(
		() => {
			if (column instanceof DurationColumnModel) {
				const ticks = convertMinutesToTicks(currentValue);
				onChangeComplete(column.id, ticks);
			} else {
				onChangeComplete(column.id, currentValue);
			}
		},
		[column, currentValue, onChangeComplete]
	)

	const setCurrentValueCallback = useCallback(
		(value: any) => {
			// for boolean column, change is completed on every change,
			// and for other columns, change is completed on blur
			if (column instanceof BooleanColumnModel) {
				setCurrentValue(value);
				onChangeComplete(column.id, value);
			} else {
				setCurrentValue(value)
			}
		},
		[column, onChangeComplete]
	)

	const props = useMemo(
		() => {
			return {
				value: currentValue,
				onChange: setCurrentValueCallback,
				onBlur: onBlurCallback,
				loading
			}
		},
		[currentValue, setCurrentValueCallback, onBlurCallback, loading]
	)

	if (column instanceof OptionColumnModel) {
		return <OptionEditableCell column={column} {...props} formatterCell={formatterCell} />
	} else if (column instanceof OptionsColumnModel) {
		return <OptionsEditableCell column={column} {...props} formatterCell={formatterCell} />
	} else if (column instanceof DateColumnModel) {
		return <DateEditableCell {...props} />
	} else if (column instanceof LinkColumnModel) {
		return <LinkEditableCell {...props} />
	} else if (column instanceof NumberColumnModel || column instanceof CurrencyColumnModel || column instanceof DurationColumnModel) {
		return <NumberEditableCell {...props} />
	} else if (column instanceof TimeColumnModel) {
		return <TimeEditableCell column={column} {...props} />
	} else if (column instanceof BooleanColumnModel) {
		return <BooleanEditableCell {...props} />
	} else if (column instanceof StringColumnModel) {
		return <StringEditableCell {...props} />
	}
	// TODO:code_improvements FormattedReference is missing in editable cells, instead when there is need for editing we use Option(s)
	// so we should create some support for FormattedReference editing to make it cleaner

	return <></>
}
