import { ActionType, FetchingActionType } from "features/Persist/actionTypes"
import { ColumnsOrderType, ColumnsWidthType, TablesConfig } from "./reducer"
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from "../action"
import store from "base/reducer/store"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { FilterType, SortType } from "components/Table"

export const SET_COLUMNS_ORDER_TABLE_CONFIG = 'SET_COLUMNS_ORDER_TABLE_CONFIG'
export const SET_COLUMNS_WIDTH_TABLE_CONFIG = 'SET_COLUMNS_WIDTH_TABLE_CONFIG'
export const SET_FILTERS_TABLE_CONFIG = 'SET_FILTERS_TABLE_CONFIG'
export const SET_SORT_TABLE_CONFIG = 'SET_SORT_TABLE_CONFIG'

export type TablesConfigActionType = ActionType & {
	tablesConfig: TablesConfig
}

export type ColumnsOrderActionType = ActionType & {
	tableId: string
	columnsOrder: ColumnsOrderType
}

export type ColumnsWidthActionType = ActionType & {
	tableId: string
	columnsWidth: ColumnsWidthType
}

export type FiltersActionType = ActionType & {
	tableId: string
	filters: FilterType[]
}

export type SortActionType = ActionType & {
	tableId: string
	sort: SortType
}

const viewType = 'TableConfig';

const getTableConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

const updateTableConfigAction = () => {
	const state = store.getState();
	const tableConfig = state.persistedTablesConfig.value;

	return updateUserTableViewConfigAction(viewType, tableConfig);
}

export const persistTableConfigAction = async () => {
	const state = store.getState();

	if (state.persistedTablesConfig.fetching) {
		return;
	}

	store.dispatch(setTableConfigFetchingAction(true));
	const response = await tryCatchJsonByAction(getTableConfigAction);
	if (response.success && response.value) {
		store.dispatch(setTablesConfigAction(response.value.content));
	} else {
		store.dispatch(setTableConfigFetchingAction(false));
	}
}

// persist configure view redux
export const TABLE_CONFIG_FETCHING = 'TABLE_CONFIG_FETCHING'
export const SET_TABLE_CONFIG = 'SET_TABLE_CONFIG'

const setTableConfigFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TABLE_CONFIG_FETCHING,
		fetching
	}
}

const setTablesConfigAction = (tablesConfig: TablesConfig): TablesConfigActionType => {
	return {
		type: SET_TABLE_CONFIG,
		tablesConfig
	}
}

// public methods

export const setColumnsOrderAction = async (tableId: string, columnsOrder: ColumnsOrderType | undefined) => {
	store.dispatch({
		type: SET_COLUMNS_ORDER_TABLE_CONFIG,
		tableId,
		columnsOrder,
	} as ColumnsOrderActionType);

	return await updateTableConfigAction();
}

export const setColumnsWidthAction = async (tableId: string, columnsWidth: ColumnsWidthType) => {
	store.dispatch({
		type: SET_COLUMNS_WIDTH_TABLE_CONFIG,
		tableId,
		columnsWidth,
	} as ColumnsWidthActionType);

	return await updateTableConfigAction();
}

export const setFiltersAction = async (tableId: string, filters: FilterType[]) => {
	store.dispatch({
		type: SET_FILTERS_TABLE_CONFIG,
		tableId,
		filters
	} as FiltersActionType);

	return await updateTableConfigAction();
}

export const setSortAction = async (tableId: string, sort: SortType) => {
	store.dispatch({
		type: SET_SORT_TABLE_CONFIG,
		tableId,
		sort
	} as SortActionType);

	return await updateTableConfigAction();
}
