import { FormContext, IFormContext, ValidatorFunctionType, CheckboxField } from 'components/Form';
import { useCallback, memo, useMemo } from 'react';
import { ModuleActivityEnum } from 'services/tenantManagementService';

const getI18nKeyFromModuleActivityEnum = (moduleEnum: ModuleActivityEnum) => {
	switch (moduleEnum) {
		case ModuleActivityEnum.Project:
			return 'i18n.p.myProjects.maintainProject.projectData.module.project';
		case ModuleActivityEnum.Ticket:
			return 'i18n.p.myProjects.maintainProject.projectData.module.ticket';
		case ModuleActivityEnum.Time:
			return 'i18n.p.myProjects.maintainProject.projectData.module.timeTravel';
		case ModuleActivityEnum.Risk:
			return 'i18n.p.myProjects.maintainProject.projectData.module.risk';
		case ModuleActivityEnum.Communication:
			return 'i18n.p.myProjects.maintainProject.projectData.module.communication';
		case ModuleActivityEnum.Schedule:
			return 'i18n.p.myProjects.maintainProject.projectData.module.schedule';
		case ModuleActivityEnum.Scope:
			return 'i18n.p.myProjects.maintainProject.projectData.module.scope';
		case ModuleActivityEnum.Testing:
			return 'i18n.p.myProjects.maintainProject.projectData.module.testing';
		case ModuleActivityEnum.Training:
			return 'i18n.p.myProjects.maintainProject.projectData.module.training';
		case ModuleActivityEnum.Finance:
			return 'i18n.p.myProjects.maintainProject.projectData.module.finance';
		case ModuleActivityEnum.Reporting:
			return 'i18n.p.myProjects.maintainProject.projectData.module.reporting';
	}
}

type Props = {
	index: number
	values: any
	errors: any
	disabled?: boolean
	onFieldChange(index: number, id: string, value: any): void
	registerValidators(index: number, id: string, validatorFunctions: Array<ValidatorFunctionType>): void
	validateField(index: number, id: string, value?: any): void
}

export const ProjectActiveModuleCheckbox = ({ index, disabled, values, registerValidators, errors = {}, onFieldChange, validateField }: Props) => {
	const registerValidatorsCallback = useCallback(
		(id: string, validatorFunctions: Array<ValidatorFunctionType>) => registerValidators(index, id, validatorFunctions),
		[index, registerValidators]
	)

	const onFieldChangeCallback = useCallback(
		(id: string, value: any) => {
			onFieldChange(index, id, value);
		},
		[index, onFieldChange]
	)

	const validateFieldCallback = useCallback(
		(id: string, value?: any) => {
			validateField(index, id, value);
		},
		[index, validateField]
	)

	const context: IFormContext = useMemo(
		() => {
			return {
				values,
				errors,
				disabled,
				onFieldChange: onFieldChangeCallback,
				validateField: validateFieldCallback,
				registerValidators: registerValidatorsCallback
			}
		},
		[values, errors, disabled, validateFieldCallback, onFieldChangeCallback, registerValidatorsCallback]
	)

	return (
		<FormContext.Provider value={context}>
			<CheckboxField
				id='isActive'
				label={getI18nKeyFromModuleActivityEnum(context.values.id)}
			/>
		</FormContext.Provider>
	);
};

export default memo(ProjectActiveModuleCheckbox);
