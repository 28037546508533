import styles from './tableMatrix.module.scss';

export type TableMatrixType = {
	rowHeaders: string[]
	headers: string[]
	data: string[][]
}

export const TableMatrix = ({ rowHeaders, headers, data }: TableMatrixType) => {
	const rows: React.ReactNode[] = [];
	for (let i = 0; i < data.length; i++) {
		rows.push(
			<tr key={i}>
				<th className={styles.row_header}>
					{rowHeaders[i + 1]}
				</th>
				{data[i].map((dataValue, index) => (
					<td key={index} className={styles.cell}>
						{dataValue}
					</td>
				))}
			</tr>
		);
	}

	// TODO: translations
	return (
		<table>
			<thead>
				<tr>
					<th className={styles.column_header}>
						<div>{headers[0]}</div>
						<hr className={styles.hr} />
						<div>{rowHeaders[0]}</div>
					</th>
					{headers.slice(1).map((column, index) => (
						<th key={index} className={styles.column_header}>
							{column}
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	)
}
