import { DeleteIcon, LinkIcon, PlusIcon } from 'components/icons/icons'
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styles from './linkCell.module.scss'
import { Input } from 'components/Form'
import { getAbsoluteUrl } from '../LinkCell/helper'
import { createPortal } from 'react-dom'
import { Cover } from 'components/Cover'
import { getCssVariableValue } from 'utils/cssVariablesUtils'

const isValidLink = (link: string) => {
	const regex = /^(https?:\/\/|www\.)[\w-]+(\.[\w-]+)+([/?#].*)?$/i
	return regex.test(link);
}

type Props = {
	value: string
	onChange(value: string): void
	onBlur(): void
}

export const LinkEditableCell = ({ value, onChange, onBlur }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [newLink, setNewLink] = useState('');
	const [showLinkInput, setShowLinkInput] = useState(false);
	const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});
	const [errorStyle, setErrorStyle] = useState<React.CSSProperties>({});

	const openCellDropdownCallback = useCallback(
		() => setIsOpen(true),
		[]
	)

	const closeCellDropdownCallback = useCallback(
		() => setIsOpen(false),
		[]
	)

	const handleOpenLinkCallback = useCallback(
		(link: string) => {
			const url = getAbsoluteUrl(link);
			window.open(url, '_blank');
			closeCellDropdownCallback();
		},
		[closeCellDropdownCallback]
	)

	const handleDeleteCallback = useCallback(
		(uri: string) => {
			const updatedLinks = value.split(';').filter(link => link !== uri).join(';');
			onChange(updatedLinks);
		},
		[value, onChange]
	)

	const handleOnChangeCallback = useCallback(
		() => {
			if (isValidLink(newLink)) {
				const updatedLinks = value ? `${value};${newLink}` : newLink;
				onChange(updatedLinks);
				setNewLink('');
				setShowLinkInput(false);
			} else {
				setErrorStyle({ borderColor: getCssVariableValue('--error-color') });
			}
		},
		[value, newLink, onChange]
	)

	const handleOnBlurCallback = useCallback(
		() => {
			onBlur();
			handleOnChangeCallback();
		},
		[onBlur, handleOnChangeCallback]
	)

	const onKeyDownCallback = useCallback(
		(eventKey: string) => {
			if (eventKey === 'Enter') {
				handleOnChangeCallback();
			}
		},
		[handleOnChangeCallback]
	)

	const linkCount = useMemo(
		() => (value || '').split(';').filter(x => !!x).length,
		[value]
	)

	const dropdownContentMemo = useMemo(
		() => {
			const dropdownContent = (
				<div className={styles.dropdown_content} style={dropdownStyle}>
					{(value || '').split(';').filter(x => !!x).map((link, i) => (
						<div className={styles.link_item} key={i}>
							<div
								className={styles.link}
								onClick={() => handleOpenLinkCallback(link)}
								title={link}
							>
								{link}
							</div>
							<div className={styles.delete_icon}>
								<DeleteIcon width={10} height={10} fill='currentColor' onClick={() => handleDeleteCallback(link)} />
							</div>
						</div>
					))}
					{!showLinkInput ?
						<div className={styles.add_icon} onClick={() => setShowLinkInput(true)}>
							<PlusIcon width={10} height={10} fill='currentColor' />
						</div>
						:
						<Input
							value={newLink}
							onChange={(newValue: string) => setNewLink(newValue)}
							onBlur={handleOnBlurCallback}
							onKeyDown={onKeyDownCallback}
							style={errorStyle}
							hideMaxLength
							size='small'
							focus
						/>
					}
				</div>
			);

			return createPortal(dropdownContent, document.body);
		},
		[value, dropdownStyle, showLinkInput, newLink, errorStyle, handleOnBlurCallback, handleOpenLinkCallback, handleDeleteCallback, onKeyDownCallback]
	)

	const calculateDropdownPortalStyle = useCallback(
		() => {
			const container = containerRef.current;

			if (container) {
				const { bottom, top, width, left } = container.getBoundingClientRect();

				const viewportHeight = window.innerHeight;
				const spaceBelow = viewportHeight - bottom;
				const spaceAbove = top
				const dropdownHeight = linkCount >= 5 ? 160 : linkCount > 0 ? linkCount * 32 : 32; // maxHeight(5 options * 32px)
				const openAbove = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;
				const dropdownMaxWidth = 500;

				const dropdownStyle: React.CSSProperties = {
					minWidth: `${width}px`,
					maxWidth: `${dropdownMaxWidth}px`,
					left: `${left + window.scrollX}px`,
					...(openAbove ?
						{ bottom: `${viewportHeight - spaceAbove - window.scrollY}px` } :
						{ top: `${bottom + window.scrollY}px` }
					),
				};

				setDropdownStyle(dropdownStyle);
			}
		},
		[linkCount]
	)

	useLayoutEffect(
		() => {
			if (isOpen) {
				calculateDropdownPortalStyle();

				window.addEventListener('scroll', calculateDropdownPortalStyle, true);

				return () => {
					window.removeEventListener('scroll', calculateDropdownPortalStyle, true);
				}
			}
		},
		[isOpen, calculateDropdownPortalStyle]
	)

	return (
		<div ref={containerRef} className={styles.container}>
			{isOpen && <Cover onClick={closeCellDropdownCallback} transparent />}
			<div onClick={openCellDropdownCallback}>
				<LinkIcon width={12} height={12} fill='currentColor' />
				{linkCount > 0 && <span className={styles.link_badge}>{linkCount}</span>}
			</div>
			{isOpen && dropdownContentMemo}
		</div>
	)
}
