import { languages, TranslationAction } from "components/Translations/TranslationsProvider";
import { LanguagesEnum } from "services/tenantManagementService";
import appSettings from "settings/appSettings";

const LOCALE = 'locale';

export const setLocale = (locale: LanguagesEnum) => {
	localStorage.setItem(LOCALE, locale);
}

export const getLocale = (): LanguagesEnum => {
	const locale = localStorage.getItem(LOCALE);
	if (locale) {
		return locale as LanguagesEnum;
	}

	return LanguagesEnum.English;
}

export const getLanguageFromLocale = (locale: LanguagesEnum | undefined) => {
	switch (locale) {
		case LanguagesEnum.Serbian:
			return languages[LanguagesEnum.Serbian];
		case LanguagesEnum.English:
		default:
			return languages[LanguagesEnum.English];
	}
}

export const loadTranslation = async (
	routeId: string,
	dispatch: React.Dispatch<TranslationAction>,
	loadedTranslations: { [key: string]: boolean },
	loadingInProgress: Set<string>,
) => {
	if (loadedTranslations[routeId] || loadingInProgress.has(routeId)) {
		return;
	}

	dispatch({ type: 'LOAD_TRANSLATION', payload: routeId });
	const locale = getLocale();
	setLocale(locale);
	const languageCode = languages[locale].locale;
	try {
		const apiUrl = appSettings.getApiUrl();
		const translationsText = await (await fetch(`${apiUrl}/translations/${languageCode}/${routeId}.json`)).text();
		const translations = JSON.parse(translationsText);
		dispatch({ type: 'SET_TRANSLATION_LOADED', payload: { routeId, translations } });
	} catch (error) {
		console.error(`Error loading translation for ${routeId}:`, error);
	}
}

export const isNotTranslated = (value: string) => {
	return value.startsWith('i18n');
}
