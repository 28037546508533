import appSettings from "settings/appSettings";
import { useTranslations } from "./TranslationsProvider";
import styles from './translation.module.scss';

type Props = {
	// sometimes i18n is undefined somehow, so added "| undefined" just in case
	i18n: string | undefined
	args?: string[]
}

export const Translation = ({ i18n, args = [] }: Props) => {
	const markTranslation = appSettings.getMarkTranslations();
	const { translations } = useTranslations();

	if (i18n === undefined) {
		return <></>
	}

	const translation = translations[i18n];

	const replacePlaceholders = (translation: string, args: string[] = []) => {
		return translation.replace(/{{(\d+)}}/g, (_, index) => {
			return args[parseInt(index, 10)] || '';
		})
	}

	if (translation) {
		const translatedText = replacePlaceholders(translation, args);
		return <span>{translatedText}</span>;
	}

	if (markTranslation) {
		return <span className={i18n.startsWith('i18n') ? styles.missing_key : styles.not_translated}>{i18n}</span>
	}

	return <span>{i18n}</span>
}
