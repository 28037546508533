import { useMemo } from 'react';
import Button from 'components/Button';
import { RowContainer } from 'components/Layout';
import styles from './simpleDialog.module.scss';
import { Dialog } from '../Dialog';
import { Translation } from 'components/Translations/Translation';

type Props = {
	title: string
	open: boolean
	message: string
	onConfirm(): void
	onCancel(): void
}

export const SimpleDialog = ({ title, open, message, onConfirm, onCancel }: Props) => {
	const content = useMemo(
		() => {
			return (
				<>
					<div className={styles.content}>
						<Translation i18n={message} />
					</div>
					<div className={styles.footer}>
						<RowContainer justifyContent='flex-end'>
							<Button
								text='Yes'
								color='primary'
								onClick={onConfirm}
							/>
							<Button
								text='No'
								color='neutral'
								onClick={onCancel}
							/>
						</RowContainer>
					</div>
				</>
			)
		},
		[message, onCancel, onConfirm]
	)

	return (
		<Dialog
			title={title}
			open={open}
			onClose={onCancel}
			children={content}
		/>
	)
}
