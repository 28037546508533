import { Translation } from 'components/Translations/Translation';
import styles from './spinner.module.scss';

type SpinnerProps = {
	children: any,
	text?: string,
	textColor?: string
}

const Spinner = ({ children, text, textColor }: SpinnerProps) => {
	return (
		<div className={styles.spinner} style={{ color: textColor }}>
			{children}
			{text &&
				<label className={styles.text}>
					<Translation i18n={text} />
				</label>}
		</div>
	)
}

export default Spinner;
