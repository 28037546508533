import { useCallback, useEffect, useMemo } from "react"
import { GenericColumnModel, InteractionManager } from "../models"
import styles from './body.module.scss'
import { Row } from "./Row"
import { ColumnWidthMap } from "../CustomTable"
import { emptyObject } from "utils/commonHelper"

type Props = {
	columns: GenericColumnModel[]
	rowsData: any[]
	columnWidthMap: ColumnWidthMap
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	expand?: (rowData: any) => void
	collapse?: (rowData: any) => void
	interactionManager: InteractionManager
}

export const Body = ({ columns, rowsData, columnWidthMap, cellEdited, expand, collapse, interactionManager }: Props) => {
	const handleRowClickCallback = useCallback(
		(rowData: any) => {
			interactionManager.click?.(rowData);
		},
		[interactionManager]
	)

	// after row is deleted, we want generic way to deselect that row (instead of doing it in every container when delete happens)
	useEffect(
		() => {
			const doesSelectedRowExists = rowsData.includes(interactionManager.selectedRow);
			if (!doesSelectedRowExists) {
				interactionManager.click?.(emptyObject);
			}
		},
		[interactionManager, rowsData]
	)

	const rowsContent = useMemo(
		() => rowsData.map((row, rowIndex) => (
			<Row
				key={`row_${rowIndex}`}
				columns={columns}
				rowData={row}
				onClick={handleRowClickCallback}
				interactionManager={interactionManager}
				isSelected={row === interactionManager.selectedRow}
				columnWidthMap={columnWidthMap}
				cellEdited={cellEdited}
				expand={expand}
				collapse={collapse}
			/>
		)),
		[columns, rowsData, handleRowClickCallback, interactionManager, cellEdited, columnWidthMap, expand, collapse]
	)

	return (
		<div className={styles.container}>
			{rowsContent}
		</div>
	)
}
