import { ActiveModule, ModuleActivityEnum } from 'services/tenantManagementService';

export const isFieldDisabled = (id: ModuleActivityEnum, values: ActiveModule[]) => {
	if (id === ModuleActivityEnum.Project) {
		return true;
	}
	return false;
}

export const findActiveModule = (moduleEnum: ModuleActivityEnum, values: ActiveModule[] | undefined) => {
	return values?.find(v => v.id === moduleEnum);
}
