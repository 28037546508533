import Button from 'components/Button';
import { Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterType, SemaphoreColumnModel } from 'components/Table';
import { SemaphoreField } from 'components/Form';

type Props = {
	column: SemaphoreColumnModel
	filters: FilterType[]
	onSave: (filters: FilterType[]) => void
	onCancel: () => void
}

type State = {
	selectedValue: any
}

export const SemaphoreFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<State>({ selectedValue: '' })

	useEffect(
		() => {
			const currentFilter = findFilter(filters, column.id);

			if (currentFilter) {
				setValues({ selectedValue: currentFilter.value});
			} else {
				setValues({ selectedValue: ''});
			}
		},
		[filters, column.id]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.id);
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}

			if (values.selectedValue) {
				newFilters.push({
					columnId: column.id,
					operation: GenericFilterOperationEnum.EQUALS,
					value: values.selectedValue
				})
			}

			onSave && await onSave(newFilters);
		},
		[column.id, filters, values, onSave]
	)

	const clearCallback = useCallback(
		() => {
			setValues({ selectedValue: '' }) ;
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='i18n.button.apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SemaphoreField
							id='selectedValue'
							label='i18n.label.selectOption'
							colors={column.colors}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='i18n.button.clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
