import { useCallback, useMemo } from 'react'
import styles from './autoComplete.module.scss'

type Props = {
	item: any
	getItemText(item: any): string | undefined
	onClick(item: any): void
	isSelected?: boolean
	getItemStyle?: (item: any) => React.CSSProperties
}

export const Option = ({ item, getItemText, onClick, isSelected, getItemStyle }: Props) => {
	const onClickCallback = useCallback(
		() => {
			onClick(item);
		},
		[item, onClick]
	)

	const itemStyleMemo = useMemo(
		() => getItemStyle && getItemStyle(item),
		[item, getItemStyle]
	)

	const className = `${styles.option} ${isSelected ? styles.selected : ''}`

	return (
		<div className={className} onMouseDown={onClickCallback} style={itemStyleMemo}>
			{getItemText(item)}
		</div>
	)
}
