import { MultiSelect } from 'components/Form'
import { useMemo } from 'react'
import { FormatterCell, OptionsColumnModel } from '../../models'

type Props = {
	column: OptionsColumnModel<any>
	value: (string | number)[]
	onChange(value: (string | number)[]): void
	onBlur(): void
	loading: boolean
	formatterCell: FormatterCell
}

export const OptionsEditableCell = ({ column, value, onChange, onBlur, loading, formatterCell }: Props) => {
	const specificPropsMemo = useMemo(
		() => {
			const { items, getItemId, getItemText, getItems } = column;
			const newItems = getItems ? getItems(formatterCell) : items;

			return {
				items: newItems,
				getItemId,
				getItemText,
				getItems
			}
		},
		[column, formatterCell]
	)

	return (
		<MultiSelect
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			{...specificPropsMemo}
			loading={loading}
			size='small'
		/>
	)
}
