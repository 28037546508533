import { memo, MouseEvent, PointerEvent, useCallback, useMemo, useRef } from 'react'
import styles from './body.module.scss'
import { Cell } from './Cell'
import { GenericColumnModel, InteractionManager } from '../models'
import { ColumnWidthMap } from '../CustomTable'
import { MenuExpanderIcon as RowExpanderIcon } from 'components/icons/icons'

type Props = {
	columns: GenericColumnModel[]
	rowData: any
	onClick: (rowData: any) => void
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	expand?: (rowData: any) => void
	collapse?: (rowData: any) => void
	isSelected: boolean
	columnWidthMap: ColumnWidthMap
	interactionManager: InteractionManager
}

const RowWithoutMemo = ({ columns, rowData, onClick, cellEdited, expand, collapse, isSelected, columnWidthMap, interactionManager }: Props) => {
	const rowRef = useRef<HTMLDivElement | null>(null);

	const toggleRowExpandCollapseCallback = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();

			if (!rowData.expanded) {
				expand?.(rowData);
			} else {
				collapse?.(rowData);
			}
		},
		[rowData, expand, collapse]
	)

	const columnsMemo = useMemo(
		() => columns.filter(column => column.frozen !== 'end'),
		[columns]
	)

	const frozenRightColumns = useMemo(
		() => columns.filter(column => column.frozen === 'end'),
		[columns]
	)

	const togglerClassName = useMemo(
		() => rowData.expanded ? styles.toggler_expanded : styles.toggler_collapsed,
		[rowData.expanded]
	)

	const cellsContent = useCallback(
		(newColumns: GenericColumnModel[]) => {
			return newColumns.map((column) => {
				if (column.isNested) {
					return (
						<div
							key={column.id}
							className={styles.cell}
							style={{ width: `${columnWidthMap[column.id]}px` }}
							onClick={toggleRowExpandCollapseCallback}
							data-type='cell'
						>
							<span className={`${styles.row_toggler} ${styles[`level_${rowData.level}`]}`} data-columnid={column.id}>
								{rowData.hasChildren &&
									<RowExpanderIcon
										width={12}
										height={12}
										fill="currentColor"
										className={togglerClassName}
									/>
								}
							</span>
						</div>
					)
				}

				return (
					<Cell
						column={column}
						key={column.id}
						value={rowData[column.id]}
						columnWidth={columnWidthMap[column.id]}
						rowData={rowData}
						cellEdited={cellEdited}
					/>
				)
			})
		},
		[rowData, columnWidthMap, cellEdited, toggleRowExpandCollapseCallback, togglerClassName]
	)

	// just to make onAuxClick trigger on middle click
	const onPointerDownCallback = useCallback(
		(e: PointerEvent) => {
			if (e.button === 1) {
				e.preventDefault();
			}
		},
		[]
	)

	const onClickCallback = useCallback(
		() => onClick(rowData),
		[onClick, rowData]
	)

	const onMiddleClickCallback = useCallback(
		(e: MouseEvent) => {
			if (e.button === 1) {
				interactionManager.middleClick?.(rowData);
			}
		},
		[interactionManager, rowData]
	)

	const onDoubleClickCallback = useCallback(
		() => interactionManager.doubleClick?.(rowData),
		[interactionManager, rowData]
	)

	return (
		<div className={styles.row_wrapper}>
			<div
				className={`${styles.row} ${isSelected ? styles.selected : ''}`}
				onClick={onClickCallback}
				onPointerDown={onPointerDownCallback}
				onAuxClick={onMiddleClickCallback}
				onDoubleClick={onDoubleClickCallback}
				ref={rowRef}
			>
				{columnsMemo.length > 0 && (
					frozenRightColumns.length > 0 ?
						<div className={styles.cells_wrapper}>
							{cellsContent(columnsMemo)}
						</div>
						:
						cellsContent(columnsMemo)
				)}
				{frozenRightColumns.length > 0 &&
					cellsContent(frozenRightColumns)
				}
			</div>
		</div>
	)
}

export const Row = memo(RowWithoutMemo);
