import { TimePicker } from 'components/Form'
import { TimeColumnModel } from '../../models'

type Props = {
	column: TimeColumnModel
	value: number
	onChange(value: number): void
	onBlur(): void
	loading: boolean
}

export const TimeEditableCell = ({ column, value, onChange, onBlur, loading }: Props) => {
	return (
		<TimePicker
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			loading={loading}
			size='small'
			isDuration={column.isDuration}
		/>
	)
}
