import { useState, useCallback, useEffect, } from 'react';
import { Dialog } from 'components/Dialog';
import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { MultiList, MultiListType } from 'components/DragNDrop';
import ColumnContainer from 'components/Layout/ColumnContainer';
import { getTableColumnsDataByMultiList, getMultiListDataByTableColumns } from './multiListHelper';
import { GenericColumnModel, InteractionManager } from 'components/Table';
import { ResetButtons } from './ResetButtons';

export type ConfigureViewColumnType = {
	id: string
	title: string
	visible?: boolean
	frozen?: 'start' | 'end'
}

type Props = {
	visibleColumns: GenericColumnModel[]
	hiddenColumns: GenericColumnModel[]
	interactionManager: InteractionManager
}

export const ConfigureViewCustomTable = ({ visibleColumns, hiddenColumns, interactionManager }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentMultiList, setCurrentMultiList] = useState<MultiListType>(
		getMultiListDataByTableColumns(visibleColumns, hiddenColumns)
	);
	const [loading, setLoading] = useState(false);

	useEffect(
		() => {
			setCurrentMultiList(getMultiListDataByTableColumns(visibleColumns, hiddenColumns));
		},
		[visibleColumns, hiddenColumns]
	)

	const openModalCallback = useCallback(
		() => {
			setIsModalOpen(true)
		},
		[]
	);

	const closeModalCallback = useCallback(
		() => setIsModalOpen(false),
		[]
	);

	const saveCallback = useCallback(
		async () => {
			setLoading(true);
			const newTableColumns = getTableColumnsDataByMultiList(
				currentMultiList,
				visibleColumns,
				hiddenColumns
			);
			const visibleConfigureViewColumns = newTableColumns.filter(column => column.visible);
			const columnOrder: string[] = visibleConfigureViewColumns.map(column => column.id)
			await interactionManager.reorderColumn?.(columnOrder);

			setLoading(false);
			setIsModalOpen(false);
		},
		[currentMultiList, visibleColumns, hiddenColumns, interactionManager]
	)

	return (
		<>
			<Button
				onClick={openModalCallback}
				color='neutral'
				text='i18n.button.configureView'
			/>
			<Dialog
				title='i18n.button.configureView'
				onClose={closeModalCallback}
				open={isModalOpen}
			>
				<ColumnContainer margin='medium'>
					<MultiList
						onDragEnd={setCurrentMultiList}
						initialMultiList={currentMultiList}
					/>
					<RowContainer justifyContent='space-between'>
						<ResetButtons
							interactionManager={interactionManager}
						/>
						<RowContainer>
							<Button
								onClick={saveCallback}
								text='i18n.button.save'
								isLoading={loading}
								disabled={currentMultiList.columnMap['selected'].elementIds.length === 0}
							/>
							<Button
								onClick={closeModalCallback}
								color='neutral'
								text='i18n.button.cancel'
								disabled={loading}
							/>
						</RowContainer>
					</RowContainer>
				</ColumnContainer>
			</Dialog>
		</>
	)
}
