import { RowContainer } from 'components/Layout';
import styles from './resetButtons.module.scss';
import { InteractionManager } from '../models';
import { ResetFilterIcon, ResetOrderIcon, ResetSortIcon, ResetWidthIcon } from 'components/icons/icons';
import { useCallback, useMemo } from 'react';

type Props = {
	interactionManager: InteractionManager
}

export const ResetButtons = ({ interactionManager }: Props) => {
	const resetColumnsWidthCallback = useCallback(
		() => interactionManager.resetAllColumnWidths?.(),
		[interactionManager]
	)

	const resetColumnsOrderCallback = useCallback(
		() => interactionManager.reorderColumn?.(undefined),
		[interactionManager]
	)

	const resetColumnsFilterCallback = useCallback(
		() => interactionManager.filter?.([]),
		[interactionManager]
	)

	const resetColumnsSortCallback = useCallback(
		() => interactionManager.sort?.(undefined),
		[interactionManager]
	)

	const icons = useMemo(
		() => [
			<ResetWidthIcon width={16} height={16} fill='currentColor' onClick={resetColumnsWidthCallback} />,
			<ResetOrderIcon width={16} height={16} fill='currentColor' onClick={resetColumnsOrderCallback} />,
			<ResetFilterIcon width={16} height={16} fill='currentColor' onClick={resetColumnsFilterCallback} />,
			<ResetSortIcon width={16} height={16} fill='currentColor' onClick={resetColumnsSortCallback} />
		],
		[resetColumnsFilterCallback, resetColumnsOrderCallback, resetColumnsSortCallback, resetColumnsWidthCallback]
	)

	const titles = ['width', 'order', 'filter', 'sorting'];

	return (
		<RowContainer>
			{icons.map((icon, idx) => (
				<div key={idx} className={styles.icon} title={`Reset columns ${titles[idx]}`}>
					{icon}
				</div>
			))}
		</RowContainer>
	)
}
