import { InputNumber } from "components/Form"

type Props = {
	value: number
	onChange(value: number): void
	onBlur(): void
	loading: boolean
}

export const NumberEditableCell = ({ value, onChange, onBlur, loading }: Props) => {
	return (
		<InputNumber
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			loading={loading}
			size='small'
		/>
	)
}
