import { Translation } from "components/Translations/Translation"

type Props = {
	title: string
	children: any
}

export const FormGroup = ({ title, children }: Props) => {
	return (
		<>
			<h5><Translation i18n={title} /></h5>
			{children}
		</>
	)
}
