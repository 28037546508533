// https://codesandbox.io/s/react-router-preventing-transitions-1yvzb?from-embed=&file=/example.js:720-732
// https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
// https://stackoverflow.com/questions/52447828/is-there-a-way-to-modify-the-page-title-with-react-router-v4/54112771#54112771

import { useCallback, useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';
import { Dialog } from 'components/Dialog';
import { ColumnContainer, RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { Translation } from 'components/Translations/Translation';

type Props = {
	shouldBlockNavigation(): boolean
}

export const UnsavedChangesGuard = ({ shouldBlockNavigation }: Props) => {
	const history = useHistory();

	const [opened, setOpened] = useState(false);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const closeCallback = useCallback(
		() => setOpened(false),
		[]
	)

	const handleBlockedNavigation = (nextLocation: Location): boolean => {
		if (nextLocation.pathname.startsWith(history.location.pathname)) {
			return true;
		}
		if (!confirmedNavigation && shouldBlockNavigation()) {
			setOpened(true);
			setLastLocation(nextLocation);
			return false;
		}
		return true;
	}

	const handleConfirmNavigationClick = useCallback(
		() => {
			setOpened(false);
			setConfirmedNavigation(true);
		},
		[]
	)

	useEffect(
		() => {
			if (confirmedNavigation && lastLocation) {
				// Navigate to the previous blocked location with your navigate function
				history.push(lastLocation.pathname);
			}
		},
		[history, confirmedNavigation, lastLocation]
	);

	return (
		<>
			<Prompt when message={handleBlockedNavigation} />
			<Dialog
				title='i18n.unsavedChanges.title'
				onClose={closeCallback}
				open={opened}
			>
				<ColumnContainer>
					<Translation i18n='i18n.unsavedChanges.text' />
					<RowContainer justifyContent='flex-end'>
						<Button
							text='i18n.button.discard'
							onClick={handleConfirmNavigationClick}
						/>
						<Button
							text='i18n.button.cancel'
							color='neutral'
							onClick={closeCallback}
						/>
					</RowContainer>
				</ColumnContainer>
			</Dialog>
		</>
	)
}
