import { MultiSelectField, UpdateDependantsFunctionType } from "components/Form"
import { ProjectResponse } from "services/tenantManagementService"
import { UseProjectsAndCategoriesOptions, useProjects } from "../projectHooks"
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"

type Props = UseProjectsAndCategoriesOptions & {
	id: string
	isRequired?: boolean
	disabled?: boolean
	updateDependants?: UpdateDependantsFunctionType
}

export const ProjectMultiSelectField = ({
	id,
	isRequired,
	disabled,
	updateDependants,
	...options
 }: Props) => {
	const { projects, loadingProjects } = useProjects(options);

	return (
		<MultiSelectField
			id={id}
			label='i18n.label.projects'
			items={projects}
			getItemId={(item: ProjectResponse) => item.id}
			getItemText={(item: ProjectResponse) => `${getFormatedId(EntityPrefixEnum.PROJECT, item.id)} - ${item.name}`}
			updateDependants={updateDependants}
			loading={loadingProjects}
			isRequired={isRequired}
			disabled={disabled}
		/>
	)
}
