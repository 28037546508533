import Button from 'components/Button';
import { Form, SelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterModel } from '../../Filter';
import { BaseColumnModel } from '../../../BaseColumnModel';

type Props = {
	column: BaseColumnModel
	filters: FilterModel[]
	onSave: (filters: FilterModel[]) => void
	onCancel: () => void
}

type State = {
	selectedValue: 'true' | 'false' | ''
}

const filterOptions = ['true', 'false'];

export const BooleanFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<State>({ selectedValue: ''});

	useEffect(
		() => {
			const currentFilter = findFilter(filters, column.dbFilterFieldPath);

			if (currentFilter) {
				setValues({ selectedValue: currentFilter.value});
			} else {
				setValues({ selectedValue: ''});
			}
		},
		[filters, column.dbFilterFieldPath]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.dbFilterFieldPath);
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}

			if (values.selectedValue === 'true' || values.selectedValue === 'false') {
				newFilters.push(
					new FilterModel({
						property: column.dbFilterFieldPath,
						operation: GenericFilterOperationEnum.EQUALS,
						value: values.selectedValue === 'true'
					})
				)
			}

			onSave && await onSave(newFilters);
		},
		[filters, column.dbFilterFieldPath, onSave, values]
	)

	const clearCallback = useCallback(
		() => {
			setValues({ selectedValue: '' });
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='i18n.button.apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SelectField
							id='selectedValue'
							label='i18n.label.selectOption'
							items={filterOptions}
							getItemId={item => item}
							getItemText={item => item}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='i18n.button.clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
