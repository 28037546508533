import { DeleteIcon } from 'components/icons/icons';
import styles from './dialog.module.scss';
import { isValidElement } from 'react';
import { Translation } from 'components/Translations/Translation';

type Props = {
	title: string | React.ReactElement
	onClose?(): void
	i18nArgs?: string[]
}

export const Header = ({ title, onClose, i18nArgs = [] }: Props) => {
	const isJSXElement = isValidElement(title);
	return (
		<div className={styles.header}>
			<div className={styles.title}>
				<h2>{isJSXElement ? title : (<Translation i18n={title as string} args={i18nArgs} />)}</h2>
			</div>
			<DeleteIcon fill='currentColor' width={14} height={14} className={styles.close} onClick={onClose} />
		</div>
	);
}
