import Button from 'components/Button';
import { Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterModel } from '../../Filter';
import { BaseColumnModel } from 'components/Table';

type Props = {
	column: BaseColumnModel
	filters: FilterModel[]
	onSave: (filters: FilterModel[]) => void
	onCancel: () => void
}

type State = {
	selectedValues: any[]
}

export const OptionsFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<State>({ selectedValues: [] })

	useEffect(
		() => {
			// only one AND filter can be added for options filter
			const currentFilter = findFilter(filters, column.dbFilterFieldPath);
			if (!currentFilter) {
				setValues({ selectedValues: [] });
			} else if (currentFilter?.property === column.dbFilterFieldPath) {
				setValues({ selectedValues: [currentFilter.value] })
			} else if (currentFilter.orFilters && currentFilter.orFilters.length > 0) {
				const newValues = currentFilter.orFilters
					.filter(filter => filter.property ===column.dbFilterFieldPath)
					.map(filter => filter.value)
				setValues({ selectedValues: newValues }) ;
			}
		},
		[filters, column.dbFilterFieldPath]
	)

	const submitCallback = useCallback(
		async () => {
			// only one "Parent" filter can be added for options filter
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.dbFilterFieldPath);
			let newFilter;
			if (values.selectedValues.length > 1) {
				newFilter = new FilterModel({
					orFilters: values.selectedValues.map((value: any) =>
						new FilterModel({
							property: column.dbFilterFieldPath,
							operation: GenericFilterOperationEnum.EQUALS,
							value
						})
					)
				});
			} else if (values.selectedValues.length === 1) {
				newFilter = new FilterModel({
					property: column.dbFilterFieldPath,
					operation: GenericFilterOperationEnum.EQUALS,
					value: values.selectedValues[0]
				})
			}
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}
			if (newFilter) {
				newFilters.push(newFilter);
			}
			onSave && await onSave(newFilters);
		},
		[column.dbFilterFieldPath, filters, values, onSave]
	)

	const clearCallback = useCallback(
		() => {
			setValues({ selectedValues: [] }) ;
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='i18n.button.apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<MultiSelectField
							id='selectedValues'
							label='i18n.label.selectOptions'
							items={column.options}
							getItemId={column.getItemId!}
							getItemText={column.getItemText!}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='i18n.button.clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
