import { CheckIcon } from 'components/icons/icons';
import { useCallback } from 'react';
import { ControlsCommonProps } from '../../fields';
import styles from './checkbox.module.scss';
import { OverlaySpinner } from 'components/Spinner';
import { Translation } from 'components/Translations/Translation';

export type CheckboxProps = ControlsCommonProps<boolean> & {
	labelBefore?: string
	labelBeforeAdditional?: string
	size?: 'medium' | 'small'
	loading?: boolean
}

export const Checkbox = ({ value, disabled, onChange, labelBefore, labelBeforeAdditional, size = 'medium', loading }: CheckboxProps) => {
	const onChangeCallback = useCallback(
		() => {
			if (!disabled) {
				onChange && onChange(!value);
			}
		},
		[value, disabled, onChange]
	)

	const labelClassName = `${styles.label} ${disabled || loading ? styles.disabled : ''} ${value ? styles.checked : ''}`;

	return (
		<div className={`${styles.container} ${size === 'small' ? styles.small : ''}`}>
			<label className={labelClassName} onClick={onChangeCallback}>
				{value &&
					<span className={styles.icon}>
						<CheckIcon width={16} height={16} fill='currentColor' />
					</span>
				}
				<span className={styles.text}>
					<span>
						{labelBefore && <Translation i18n={labelBefore} />}
					</span>
					{labelBeforeAdditional && <span className={styles.additionalText}>&nbsp;{labelBeforeAdditional}</span>}
				</span>
			</label>
			{/* loading */}
			{loading && <OverlaySpinner useBrandColor />}
		</div>
	)
}
