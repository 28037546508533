import { useCallback } from 'react';
import DatePickerLib from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from 'components/icons/icons';
import { ControlsCommonProps } from '../../fields';
import styles from './datePicker.module.scss';
import { OverlaySpinner } from 'components/Spinner';

export type DatePickerProps = ControlsCommonProps<Date> & {
	minDate?: Date
	maxDate?: Date
	showTime?: boolean
	dateFormat?: string
	focus?: boolean
	size?: 'medium' | 'small'
	loading?: boolean
};

export const DatePicker = ({
	value,
	onChange,
	onBlur,
	disabled,
	minDate,
	maxDate,
	showTime,
	dateFormat = 'MM/dd/yyyy',
	focus = false,
	size = 'medium',
	loading
}: DatePickerProps) => {
	const onChangeCallback = useCallback(
		(date) => onChange && onChange(date),
		[onChange]
	)

	const handleCalendarClose = useCallback(
		() => onBlur && onBlur(),
		[onBlur]
	)

	return (
		<div className={`${styles.container} ${size === 'small' ? styles.small : ''}`}>
			<DatePickerLib
				className={`${styles.date_picker} ${size === 'small' ? styles.small : ''}`}
				selected={value}
				onChange={onChangeCallback}
				onCalendarClose={handleCalendarClose}
				onBlur={onBlur}
				disabled={disabled || loading}
				minDate={minDate}
				maxDate={maxDate}
				showMonthDropdown
				showYearDropdown
				yearDropdownItemNumber={3}
				showTimeSelect={showTime}
				dateFormat={dateFormat}
				autoFocus={focus}
				portalId={'datePickerPortalId'}
			/>
			<div className={`${styles.icon} ${size === 'small' ? styles.small : ''}`}>
				<CalendarIcon width={16} height={16} fill='currentColor' />
			</div>
			{/* loading */}
			{loading && <OverlaySpinner useBrandColor />}
		</div>
	)
}
